import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationDe from './locales/de/translation.json';
import translationEn from './locales/en/translation.json';
import translationEs from './locales/es/translation.json';
import translationFr from './locales/fr/translation.json';
import translationIt from './locales/it/translation.json';
import translationJa from './locales/ja/translation.json';
import translationKo from './locales/ko/translation.json';
import translationPt from './locales/pt/translation.json';

const resources = {
    de: {
        translation: translationDe
    },
    en: {
        translation: translationEn
    },
    es: {
        translation: translationEs
    },
    fr: {
        translation: translationFr
    },
    it: {
        translation: translationIt
    },
    ja: {
        translation: translationJa
    },
    ko: {
        translation: translationKo
    },
    pt: {
        translation: translationPt
    }
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        debug: process.env.NODE_ENV === 'development',
        fallbackLng: 'en'
    });

export default i18n;
